<template>
        <div :class="`content `">
            <!-- Modal Edit -->
                <div  class="modal fade view-member" id="view-member" role="dialog" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content  radius-xl">
                                <div class="modal-header">
                                    <h6 class="modal-title fw-500" id="staticBackdropLabel">Order Detail</h6>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div v-for="(e,index) in modalDetailData" :key="index"  class="col-12">
                                    
                                            <div class="alert-icon-area alert alert-warning " role="alert">


                                                <div class="alert-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
                                                </div>

                                                <div class="alert-content">


                                                    <p>หมายเลข {{e.lottoNumber}}</p>


                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- Modal Edit -->
            <div class="container-fluid">
                
                <div class="row">
                    <div class="col-lg-12">

                        <div class="breadcrumb-main">
                            <h4 class="text-capitalize breadcrumb-title" >History</h4>
                            <div class="breadcrumb-action justify-content-center flex-wrap">
                                <div class="action-btn">
                                    <a @click="startSearch" class="btn btn-sm btn-primary btn-add color-white" style="color:white"  >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                        Search</a>
                                </div>
                                <div class="action-btn">
                                    <a @click="Refresh" style="color:white;" class="btn btn-sm btn-primary btn-add" >
                                        <i class="la la-rotate-right"></i>Refresh</a>
                                </div>

                                

                            </div>
        
                        </div>

                    </div>
                </div>
                <div class="row">
  
                    <div class="col-lg-12 mb-30">
                        <div class="card">
                            <div class="my-card-header color-dark fw-500 pt-3">
                                
                             
                        
                                <span class="float-left py-2">
                                    <span class="h4 px-3">
                                        filter
                                    </span>
                                </span>
                                <span class="float-right ">
                          
                                    <span class="max-w-px-200 my-dropdown">
                                        <label for="createdAt">createdAt : </label>
                                        <input v-model="createdAtData" type="date" placeholder="createdAt">
                    
                                    </span>
                                    
                                    
                                </span>
                                    
                    
                                 
                               
                             
                                
                            </div> 
                            <div class="card-body">

                                <div class="userDatatable global-shadow border-0 bg-white w-100">
                                    <div class="table-responsive">
                                        <table class="table mb-0 table-borderless">
                                            <thead>
                                                <tr class="userDatatable-header">
                                                    
                                                    
                                                    <th>
                                                        <p class="userDatatable-title text-center m-0">Order ID</p>
                                                    </th>
                                                    <th>
                                                        <p class="userDatatable-title text-center m-0">Mobile</p>
                                                    </th>
                                                    <th>
                                                        <p class="userDatatable-title text-center m-0">Amount</p>
                                                    </th>
                                                    <th>
                                                        <p class="userDatatable-title text-center m-0">fee</p>
                                                    </th>
                                                    <th>
                                                        <p class="userDatatable-title text-center m-0">MemberRevCharge</p>
                                                    </th>
                                                    <th>
                                                        <p class="userDatatable-title text-center m-0">Created At</p>
                                                    </th>
                                                    <th>
                                                        <p class="userDatatable-title text-center m-0">Detail</p>
                                                    </th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody id="table_tbody">

                                                <tr v-for="(e,index) in listData" :key="index" >
                                                
                                                    <td>
                                                        <div class="userDatatable-content text-center">
                                                            {{e.orderId ? e.orderId : '-'}}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="userDatatable-content text-center">
                                                            {{e.mobile ? e.mobile : '-'}}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="userDatatable-content text-center">
                                                            {{e.amount ? e.amount : '-'}}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="userDatatable-content text-center">
                                                            {{e.fee ? e.fee : '-'}}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="userDatatable-content text-center">
                                                            {{e.memberRevCharge ? e.memberRevCharge : '-'}}
                                                        </div>
                                                    </td>
                                                    
                                                    <td>
                                                        <div class="userDatatable-content text-center">
                                                            {{e.createdAt ? e.createdAt : '-'}}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul class="orderDatatable_actions mb-0 d-flex justify-content-center">
                                                            <li>
                                                                <a @click="viewDetailModal(e)" class="view">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg></a>
                                                            </li>
                                                       
                                                        </ul>
                                                    </td>
                                                    
                                                    
                                                </tr>



                                            </tbody>
                                       </table>
                                        
                                    </div>
                                    <Pagination ref="PaginationRef" :Count="count" @PaginationReturnData="PaginationReturnData"  />
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            
            </div>

        </div>
</template>

<script>


import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import Pagination from '@/components/Pagination.vue'
// amount: "80.00"
// createdAt: "2021-07-15 20:45:14"
// detailLotto: Array(1)
// 0: {id: '06dfef2d-4dd8-4f5a-84dc-d30410847168', lottoNumber: '223462'}
// length: 1
// [[Prototype]]: Array(0)
// fee: "20.00"
// memberRevCharge: "2.00"
// mobile: "0897880833"
// orderId: "9202107152045148665"
export default {
  name: 'History',
  components: {
    Pagination
  },

  data: function () {
    return {
        count:0,
        PaginationData :{},
        listData:[],
        createdAtData:'',
        onSearch:false,
        modalDetailData:[]
      }
    },
  mounted() {
     document.title = 'History'
     this.$store.dispatch('display/setNavName','History')
     this.showData();
  },
  methods: {
    async Refresh(){
        this.count=0;
        this.createdAtData='';
        this.onSearch = false;
        this.showData()
    },
    async startSearch(){
        this.count=0;
        this.onSearch = true;
        this.showData()
    },
    async showData(){
        try {
            let data = {
                start:this.PaginationData.offset,
                length:this.PaginationData.limit,
                createdAt:this.createdAtData
            }
            console.log(data)
            let getAPI = await this.$call_api('get','lotto/api/v1/getLottoOrder',data,'auth');
            console.log(getAPI)
            this.count = getAPI.data.recordsFiltered;
            this.listData = getAPI.data.data;
            
        } catch (error) {
            serviceMain.showErrorAlert(this,error)
        }
    },
    PaginationReturnData(para_val){
        this.PaginationData=para_val

        this.showData()
    },
    viewDetailModal(e){
        $("#view-member").modal('show');
        this.modalDetailData = e.detailLotto;
    }
  },
  computed:{
 
  }

}

</script>
<style scoped>
.logo-head-center{
    width: 100px;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%,-50%)
}
</style>